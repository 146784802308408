import axios from 'axios'
import {
  mapCardsApiResponse,
  mapCouponCodeToAPI,
  mapCouponApiResponse,
  mapDeleteCardRequestToApi,
  mapDeleteCardResponse,
  mapAnalyseOrderRequest,
  mapAnalyseOrderResponse,
  mapPlaceOrderRequest,
  mapPlaceOrderResponse,
  mapBrandsApiResponse,
  mapMundipaggAccessTokenResponse,
  mapCardToMundipaggApiRequest,
  mapAddCardRequestToApi,
  mapAddCardResponse,
  mapAddTicketCardRequestToApi,
  mapAddTicketCardResponse,
  mapTicketOrderResponse
} from '../../dto/payment'
import handleRequestError from '../../utils/handleRequestError'
import { actionTypes as requestsActionTypes } from './requests'
import { setOrderCompleted, setCartCoupon, deleteCartCoupon } from './cart'
import { MUNDIPAGG_PUBLIC_KEY } from '../../config' 
import withAuthHeader from '../../utils/withAuthHeader'
import { getCardTypeByBrand } from '../../utils'

export const actionTypes = {
  SET_AVAILABLE_CARDS: 'SET_AVAILABLE_CARDS',
  SET_AVAILABLE_BRANDS: 'SET_AVAILABLE_BRANDS'
}

export const saveCVV = (cvv) => async (dispatch, getState, api) => {
  const result = {}

  try {
    dispatch({
      type: requestsActionTypes.REQUEST_SAVE_CVV,
      payload: {
        cvv
      }
		})
		
		Object.assign(result, {
			value: {
				success: true
			}
		})
  } catch (e) {
		const error = handleRequestError(e)

    Object.assign(result, {
      value: {
				error: error.message,
				success: false
			}
    })
  } finally {
		return result.value
	}
}

export const fetchPaymentMethods = () => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_FETCH_PAYMENT_METHODS,
      payload: {
        loading: true,
      }
    })
    const url = `Cartoes/Listar/${getState().store.id}?delivery=true`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapCardsApiResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true,
        onlineCards: response.availableOnlineCards,
        offlineCards: response.availableOfflineCards
      }
      dispatch({
        type: actionTypes.SET_AVAILABLE_CARDS,
        payload: {
          onlineCards: response.availableOnlineCards,
          offlineCards: response.availableOfflineCards
        }
      })
    } else {
      result = {
        error: response.message
      }
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_FETCH_PAYMENT_METHODS,
    payload: {
      success: true
    }
  })
  return result
}

export const fetchAvailableCardBrands = () => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_FETCH_CARD_BRANDS,
      payload: {
        loading: true,
      }
    })
    const url = `Cartoes/ListarPorAplicativo/`
    const apiResult = await api.get(url)
    const response = mapBrandsApiResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true,
        brandsImages: response.brandsImages
      }
      dispatch({
        type: actionTypes.SET_AVAILABLE_BRANDS,
        payload: {
          brandsImages: response.brandsImages
        }
      })
    } else {
      result = {
        error: response.message
      }
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_FETCH_CARD_BRANDS,
    payload: {
      success: true
    }
  })
  return result
}

export const addCoupon = (coupon) => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_ADD_COUPON,
      payload: {
        loading: true,
      }
    })
    const url = `Cupons/Cadastrar`
    const dto = mapCouponCodeToAPI(coupon)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapCouponApiResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true,
        id: response.id,
        code: coupon,
        value: response.value,
        type: response.type
      }
      dispatch(setCartCoupon({
        id: response.id,
        code: coupon,
        value: response.value,
        type: response.type
      }))
    } else {
      result = {
        error: response.message
      }
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_ADD_COUPON,
    payload: {
      success: true
    }
  })
  return result
}

export const removeCoupon = (coupon) => async (dispatch, getState, api) => {
  const result = { value: null }
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_ADD_COUPON,
      payload: {
        loading: true
      }
    })

    dispatch(deleteCartCoupon())

    Object.assign(result, { value: { success: true } })
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  }

  dispatch({
    type: requestsActionTypes.REQUEST_ADD_COUPON,
    payload: {
      success: true
    }
  })

  return result.value
}

const getMundipaggAccessToken = () => async (dispatch, getState, api) => {
  const url = `Cartoes/ObterAccessToken`
  const apiResult = await api.post(url, {}, withAuthHeader())
  const response = mapMundipaggAccessTokenResponse(apiResult.data)
  return response
}

const addCardMundipagg = ({card, mundipaggUserId, accessToken}) => 
async (dispatch, getState, api) => {
  const url = `https://api.mundipagg.com/core/v1/customers/${mundipaggUserId}/cards?appId=${MUNDIPAGG_PUBLIC_KEY}`
  const dto = mapCardToMundipaggApiRequest(card)
  const result = await axios.post(url, dto, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  })
  return result
}

export const addCard = (card) => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_ADD_CARD,
      payload: {
        loading: true,
      }
    })
    const mundipaggData = await dispatch(getMundipaggAccessToken())
    const mundipaggCard = await dispatch(addCardMundipagg({
      card, 
      mundipaggUserId: mundipaggData.userId,
      accessToken: mundipaggData.accessToken 
    }))
    const apiData = {
      mundipaggCardId: mundipaggCard.data.id,
      mundipaggUserId: mundipaggData.userId,
      digits: mundipaggCard.data.last_four_digits,
      firstDigits: mundipaggCard.data.first_four_digits,
      expMonth: mundipaggCard.data.exp_month,
      expYear: mundipaggCard.data.exp_year,
      brandId: card.brandId,
      type: getCardTypeByBrand(card.brandId),
      cpf: card.cardCpf
    }
    const url = `Cartoes/Cadastrar`
    const dto = mapAddCardRequestToApi(apiData)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapAddCardResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true
      }
    } else {
      throw(new Error(response.message))
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_ADD_CARD,
    payload: {
      success: true
    }
  })
  return result
}

export const addTicketCard = (card) => async (dispatch, getState, api) => {
  let result = {}
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_ADD_CARD,
      payload: {
        loading: true,
      }
    })
    const url = `Ticket/Cadastrar`
    const dto = mapAddTicketCardRequestToApi(card)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapAddTicketCardResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true
      }
    } else {
      throw(new Error(response.message))
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_ADD_CARD,
    payload: {
      success: true
    }
  })
  return result
}

export const deleteCard = (card) => async (dispatch, getState, api) => {
	let result = {}

  try {
    dispatch({
      type: requestsActionTypes.REQUEST_DELETE_CARD,
      payload: {
        loading: true,
      }
    })
    const url = `Cartoes/Excluir`
    const dto = mapDeleteCardRequestToApi(card)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapDeleteCardResponse(apiResult.data)
    if (response.success) {
      result = {
        success: true
      }
    } else {
      result = {
        error: response.message
      }
    }
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)
    result = {
      error: error.message,
      success: false
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_DELETE_CARD,
    payload: {
      success: true
    }
  })
  return result
}

export const ticketOrder = (orderData) => async (dispatch, getState, api) => {
  try {
    return ticketOrderStrategy.init({
      dispatch,
      api,
      orderData
    })
  } catch (e) {
    console.log(e)

    const error = handleRequestError(e)

    return {
      error: error.message,
      success: false
    }
  }
}

export const analyseOrder = (orderData) => async (dispatch, getState, api) => {
  try {
    return analysisOrderStrategy.init({
      dispatch,
      api,
      orderData
    })
  } catch (e) {
    console.log(e)

    const error = handleRequestError(e)

    return {
      error: error.message,
      success: false
    }
  }
}

const placeOrderStrategy = {
  init: async ({
    orderData,
    dispatch,
    api
  }) => {
    dispatch({
      type: requestsActionTypes.REQUEST_PLACE_ORDER,
      payload: {
        loading: true,
      }
    })
    const analysisResponse = await dispatch(analyseOrder(orderData))

    return placeOrderStrategy[analysisResponse.callback]({
      orderData,
      dispatch,
      api,
      response: analysisResponse
    })
  },
  success: ({
    response,
    dispatch
  }) => {
    console.log(response)
    dispatch(setOrderCompleted(response))

    return response
  },
  ticket: async ({
    orderData,
    response,
    dispatch,
    api
  }) => {
    const ticketResponse = await dispatch(ticketOrder(orderData))

    return placeOrderStrategy[ticketResponse.callback]({
      orderData,
      dispatch,
      api,
      response: {
        ...ticketResponse,
        orderId: response["orderId"],
        deliveryEstimate: response["deliveryEstimate"],
        deliveryStatus: response["deliveryStatus"]
      }
    })
  },
  failed: ({ dispatch, response }) => {
    const error = response && response.message ? response.message : 'Ocorreu um erro ao finalizar a compra.'
    dispatch({
      type: requestsActionTypes.REQUEST_PLACE_ORDER,
      payload: {
        loading: false,
        error
      }
    })

    return { error }
  },
  analysisSuccess: async ({
    orderData,
    dispatch,
    api,
    response: analysisResponse
  }) => {
    const placeOrderData = {
      ...orderData, 
      konduto: analysisResponse.konduto
    }

    const url = `Pedidos/Adicionar`
    const dto = mapPlaceOrderRequest(placeOrderData)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapPlaceOrderResponse(apiResult.data)
    
    return placeOrderStrategy[response.callback]({
      orderData,
      response,
      dispatch,
      api
    })
  },
  analysisFailed: ({
    response
  }) => {
    return response
  }
}

const ticketOrderStrategy = {
  init: async ({
    orderData,
    dispatch,
    api
  }) => {
    const url = `Ticket/ObterStatusPagamento`
    const apiResult = await api.get(url, withAuthHeader())
    const response = mapTicketOrderResponse(apiResult.data)

    return ticketOrderStrategy[response.callback]({ response })
  },
  success: ({
    response,
    dispatch
  }) => {
    return {
      success: true,
      callback: 'success'
    }
  },
  failed: (response) => {
    return {
      error: response.message,
      callback: 'failed'
    }
  }
}

const analysisOrderStrategy = {
  init: async ({
    orderData,
    dispatch,
    api
  }) => {
    dispatch({
      type: requestsActionTypes.REQUEST_ANALYSE_ORDER,
      payload: {
        loading: true,
      }
    })
    const url = `Pedidos/Analisar`
    const dto = mapAnalyseOrderRequest(orderData)
    const apiResult = await api.post(url, dto, withAuthHeader())
    const response = mapAnalyseOrderResponse(apiResult.data)

    return analysisOrderStrategy[response.callback]({ response })
  },
  success: ({
    response
  }) => {
    return {
      success: true,
      konduto: response.konduto,
      callback: 'analysisSuccess'
    }
  },
  failed: (response) => {
    return {
      error: response.message,
      callback: 'analysisFailed'
    }
  }
}

export const placeOrder = (orderData) => async (dispatch, getState, api) => {
  try {
    return placeOrderStrategy.init({
      dispatch,
      api,
      orderData
    })
  } catch (e) {
    if (e.response) {
      const response = mapPlaceOrderRequest(e.response.data)
      return {
        error: response.message,
        success: false
      }
    }
    
    const error = handleRequestError(e)
    return {
      error: error.message,
      success: false
    }
  }
}

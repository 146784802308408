import Addon from '../models/addon';
import mapAPIAddonItem from './addonItem';

const mapAPIAddon = (apiAddon: any) : Addon => {
    return {
        name: apiAddon["Nome"],
        min: apiAddon["Minimo"],
        max: apiAddon["Maximo"],
        checkbox: apiAddon["Checkbox"],
        items: apiAddon["Itens"].map( (item: any) => mapAPIAddonItem(item))
    }
}

export default mapAPIAddon;
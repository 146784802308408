import axios from "axios"
import { GOOGLE_API_KEY, MULTI_STORE } from "../../config"
import {
  mapViaCepResponse,
  mapSaveAddressResponse,
  mapAddressModelToSaveAddressRequest,
  mapAddressCoordinatesToValidationRequest,
  mapAddressValidationResponse,
} from "../../dto/address"
import { handleRequestError, formatAddress } from "../../utils/"
import { actionTypes as requestsActionTypes } from "./requests"
import { setDeliveryAddress, setDeliveryTable } from "./cart"
import { getDeliveryFee, getStoreInfoAndMenu } from "./store"
import withAuthHeader from "../../utils/withAuthHeader"

export const searchCep = (cep) => async (dispatch, getState, api) => {
  let result = {}
  const url = `https://viacep.com.br/ws/${cep}/json/`
  await dispatch({
    type: requestsActionTypes.REQUEST_CEP,
    payload: {
      loading: true
    }
  })
  try {
    const cepResult = await axios.get(url)
    const address = mapViaCepResponse(cepResult.data)

    if (address.error) {
      throw new Error()
    } else {
      result = {
        success: true,
        address
      }
    }
  } catch (e) {
    if (e.response) {
      result = {
        error: e.response.data
      }
    } else {
      const error = handleRequestError(e)

      result = {
        error: error.message
      }
    }
  }
  dispatch({
    type: requestsActionTypes.REQUEST_CEP,
    payload: result
  })

  return result
}

const getLatitudeLongitude = async (address) => {
  const formattedAddress = formatAddress(address)
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${GOOGLE_API_KEY}`

  try {
    const response = await axios.get(url)
    if (response.data.results && response.data.results.length > 0) {
      return {
        success: true,
        location: response.data.results[0].geometry.location
      }
    }
    throw new Error(response.data.status)
  } catch (e) {
    const error = handleRequestError(e)

    return {
      error: error.message,
      success: false
    }
  }
}

export const validateAddress = ({ latitude, longitude }) => async (
  dispatch,
  getState,
  api
) => {
  const storeId = getState().store.id

  if (MULTI_STORE === "true" && !storeId) {
    const validation = { success: true, message: undefined, valid: true }
    return validation
  }

  const url = `enderecosdelivery/validar/${storeId}`
  const apiData = mapAddressCoordinatesToValidationRequest({
    latitude,
    longitude,
  })
  const response = await api.post(url, apiData, withAuthHeader())
  const validation = mapAddressValidationResponse(response.data)
  return validation
}

export const clearAddress = () => async (dispatch, getState, api) => {
  const response = { value: null }

  try {
    await dispatch({
      type: requestsActionTypes.REQUEST_CLEAR_ADDRESS,
      payload: {
        loading: true
      }
    })

    Object.assign(response, {
      value: {
        address: {},
        success: true,
        valid: true
      }
    })

    await dispatch(setDeliveryAddress({address: null,modality: getState().cart.delivery.modality}))
    await dispatch(getDeliveryFee())
  } catch (e) {
    console.log(e)
    const error = handleRequestError(e)

    Object.assign(response, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    dispatch({
      type: requestsActionTypes.REQUEST_SAVE_ADDRESS,
      payload: response.value
    })
  
    return response.value
  }
}

export const saveAddress = (address) => async (dispatch, getState, api) => {
  const response = { value: null }

  try {
    const deliveryModality = getState().cart.delivery.modality
    const deliveryTable = getState().cart.delivery.table
    const storeId = getState().store && getState().store.id

    await dispatch({
      type: requestsActionTypes.REQUEST_SAVE_ADDRESS,
      payload: {
        loading: true
      }
    })

    const geocode = await getLatitudeLongitude(address)

    if (!geocode.success) {
      throw new Error(geocode.error)
    }

    const validation = await dispatch(
      validateAddress({
        latitude: geocode.location.lat,
        longitude: geocode.location.lng,
      })
    )

    const locatedAddress = { value: null }
    const url = `enderecosdelivery/cadastrar`

    if (!validation.valid) {
      Object.assign(response, {
        value: {
          success: true,
          valid: false
        }
      })

      return response.value
    }

    Object.assign(locatedAddress, {
      value: {
        ...address,
        latitude: geocode.location.lat,
        longitude: geocode.location.lng
      }
    })

    if (getState().user) {
      const apiData = mapAddressModelToSaveAddressRequest(locatedAddress.value)
      const apiResponse = await api.post(url, apiData, withAuthHeader())
      const savedAddress = mapSaveAddressResponse(apiResponse.data)

      if (savedAddress.success) {
        locatedAddress.value.id = savedAddress.id
        locatedAddress.value.temp = false
      }

      if (!savedAddress.success) {
        throw new Error(savedAddress.message)
      }
    }

    const modality = deliveryModality ? deliveryModality : (
      !deliveryTable ? 4 : 1
    )

    if (locatedAddress.value) {
      await dispatch(setDeliveryAddress({
        address: locatedAddress.value,
        modality
      }))
    }
    
    Object.assign(response, {
      value: {
        address: locatedAddress.value,
        success: true,
        valid: true
      }
    })

    await dispatch(getDeliveryFee(
      geocode.location.lat, geocode.location.lng
    ))
    await dispatch(getStoreInfoAndMenu(storeId))
  } catch (e) {
    const error = handleRequestError(e)

    Object.assign(response, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    dispatch({
      type: requestsActionTypes.REQUEST_SAVE_ADDRESS,
      payload: response.value
    })

    return response.value
  }
}

export const saveTable = (table) => async (dispatch, getState, api) => {
  const response = { value: null }

  try {
    const result = await dispatch(setDeliveryTable(table))

    if (result) {
      Object.assign(response, {
        value: table
      })
    }
  } catch (e) {
    
  } finally {
    return response.value
  }
}

export const resetCepSearch = () => ({
  type: requestsActionTypes.REQUEST_CEP,
  payload: {
    loading: false,
  },
})

export const actionTypes = {
    TOGGLE_CVV_MODAL: 'TOGGLE_CVV_MODAL',
    TOGGLE_ADDRESS_MODAL: 'TOGGLE_ADDRESS_MODAL',
    TOGGLE_LOGIN_MODAL: 'TOGGLE_LOGIN_MODAL',
    SET_NAV_CURRENT_LIST: 'SET_NAV_CURRENT_LIST',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION'
}

export const toggleAddressModal = (payload) => ({
  type: actionTypes.TOGGLE_ADDRESS_MODAL,
  payload: {
    ...payload
  }
});

export const toggleCVVModal = (payload) => ({
  type: actionTypes.TOGGLE_CVV_MODAL,
  payload
})

export const toggleLoginModal = ({show, state}) => ({
  type: actionTypes.TOGGLE_LOGIN_MODAL,
  payload: {
    show,
    state: state || 0
  }
});

export const setNavCurrentList = (list) => ({
  type: actionTypes.SET_NAV_CURRENT_LIST,
  payload: list
})

export const showNotification = ({type, text, timeout}) => ({
  type: actionTypes.SHOW_NOTIFICATION,
  payload: {
    type,
    text,
    timeout
  }
})

export const clearNotification = () => ({
  type: actionTypes.SHOW_NOTIFICATION,
  payload: {
    type: null,
    text: null,
    timeout: null
  }
})
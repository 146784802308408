import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Box, Divider, Icon } from '../common/'
import {
  CartStoreName,
  CartDeliveryAddressCard,
  CartItemsList,
  CartTotals,
  CartPurchaseButton,
  CartCouponCell,
  CartCpfCell,
  CartPaymentMethod,
  CartMinimumValue
} from './'
import { device } from '../../constants/responsive'
import { CouponModal } from '../payment'
import { formatCpf } from '../../utils'
import {
  toggleAddressModal,
  toggleCVVModal,
  showNotification,
  toggleLoginModal,
} from '../../redux/actions/ui'
import { navigateTo } from '../../redux/actions/pages'
import {
  addCoupon,
  removeCoupon,
  placeOrder,
  saveCVV
} from '../../redux/actions/payment'
import { isMobile } from 'react-device-detect'
import { COUPON_TYPES } from '../../constants/payment'
import { handleGTM } from '../../redux/actions/gtm'

const Blocker = styled.div`
  width: ${(props) => props.theme.dimensions.cartWidth}px;
`

const CartHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 74px;
  background-color: white;
`

const CartContent = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    flex-grow: 1;
    height: calc(
      100% - ${(props) => props.theme.dimensions.headerHeightDesktop}px - 40px -
        74px - 55px
    );
  }
  @media ${device.mobile} {
    overflow: auto;
    height: calc(100% - 55px - 74px);
  }
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
`

const OutterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0;
  }

  @media ${device.desktop} {
    position: absolute;
    width: ${(props) => props.theme.dimensions.cartWidth}px;
    overflow: hidden;

    ${(props) =>
      props.fixedHeight &&
      `
        height: calc(100vh - ${
          props.theme.dimensions.headerHeightDesktop + 40 - (props.cartMinimumValuePresent ? 95 : 0)
        }px);
    `}

    ${(props) =>
      props.fixedHeight && props.cartMinimumValuePresent &&
      `min-height: 600px;`
    }

    @media(max-height: 720px) {
      min-height: 600px;
    }

    ${(props) =>
      props.isCheckout &&
      `
        .cartList {
            max-height: 200px;
        }
    `}

    ${(props) =>
      props.fixedPosition &&
      `
        position: fixed;
        top: ${props.theme.dimensions.headerHeightDesktop + 20}px;
        right: 10%;
        bottom: 15px;
        display: flex;
        flex-direction: column;
    `}
  }
`

const cardBrandsCVV = [3, 4, 5, 13, 14]

class CartBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      couponModalOpen: false,
      couponError: null,
      loginModalCallback: null
    }

    this.cartBoxRef = React.createRef()

    this.handlePurchaseClick = this.handlePurchaseClick.bind(this)
  }

  scrollBottomIfCartComplete = () => {
    if (this.isCartComplete() && this.cartBoxRef.current) {
      this.cartBoxRef.current.scrollTop = this.cartBoxRef.current.scrollHeight
    }
  }

  componentDidMount() {
    this.scrollBottomIfCartComplete()
  }

  componentDidUpdate(prevProps) {
    // Modal opened?
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.scrollBottomIfCartComplete()
    }
    // Login modal closed?
    if (!this.props.loginModalOpen && prevProps.loginModalOpen) {
      // Has logged in?
      if (this.state.loginModalCallback && this.props.isLoggedIn) {
        this.state.loginModalCallback()
        this.setState({ loginModalCallback: null })
      }
    }
  }
  addEventGTM = (args) => {
    this.props.handleGTM(args)
  }

  handleOpenCouponModal = () => {
    this.setState({ couponModalOpen: true })
  }

  handleCloseCouponModal = () => {
    this.setState({ couponModalOpen: false })
  }

  goToCheckout = () => {
    this.props.navigateTo('/checkout')
  }

  handleEditPayment = () => {
    if (this.props.isLoggedIn) {
      this.goToCheckout()
    } else {
      this.setState({ loginModalCallback: this.goToCheckout })
      return this.props.toggleLoginModal({ show: true })
    }
  }

  handlePurchaseClick() { 
    const products = []
    this.props.items.forEach(function (item) {
      products.push({
        product: item.product,
        quantity: item.quantity
      })
    });

    if (this.props.location.pathname !== '/checkout' && this.props.isLoggedIn) {
      const dataGTM = {
        'event': `CARRINHO - ${this.props.storeName}`,
        'ecommerce': {
          'checkout': {
            'actionField': { 'step': 1, 'option': 'Abertura Carrinho' },
            'products': products.map(function (item) {
                return {
                  'name': item.product.name,
                  'id': item.product.id,
                  'price': item.product.price,
                  'brand': null,
                  'categoria': item.product.categoryName,
                  'variant': null,
                  'quantity': item.quantity
                }
            })
          }
        }
      }

      this.addEventGTM(dataGTM)
      return this.goToCheckout()
    }

    if (this.props.location.pathname === '/checkout' && !this.isCartComplete()) {
      alert('Selecione a forma de pagamento.')

      return 
    }

    if (!this.props.isLoggedIn) {
      this.setState({ loginModalCallback: this.handlePurchaseClick })

      return this.props.toggleLoginModal({ show: true })
    }

    if (this.props.location.pathname === '/checkout' && this.props.isLoggedIn && this.isCartComplete() && cardBrandsCVV.indexOf(this.props.payment.card.paymentType) < 0 && !this.props.payment.card.cvvRequired) {
      return this.handleMakePayment()
    }

    if (this.props.location.pathname === '/checkout' && this.props.isLoggedIn && this.isCartComplete() && cardBrandsCVV.indexOf(this.props.payment.card.paymentType) < 0 && !!this.props.payment.card.cvvRequired) {
      return this.handleOpenCVVModal()
    }

    if (this.props.location.pathname === '/checkout' && this.props.isLoggedIn && this.isCartComplete() && (cardBrandsCVV.indexOf(this.props.payment.card.paymentType) >= 0 || !!this.props.payment.card.cvvRequired)) {
      return this.handleOpenCVVModal()
    }
  }

  handleMobilePurchaseClick() {
    if (this.props.isLoggedIn && this.isCartComplete() && cardBrandsCVV.indexOf(this.props.payment.card.paymentType) < 0 && !this.props.payment.card.cvvRequired) {
      return this.handleMakePayment()
    }

    if (this.props.isLoggedIn && this.isCartComplete() && cardBrandsCVV.indexOf(this.props.payment.card.paymentType) < 0 && !!this.props.payment.card.cvvRequired) {
      return this.handleOpenCVVModal()
    }

    if (this.props.isLoggedIn && this.isCartComplete() && (cardBrandsCVV.indexOf(this.props.payment.card.paymentType) >= 0 || !!this.props.payment.card.cvvRequired)) {
      return this.handleOpenCVVModal()
    }

    if (!this.props.isLoggedIn) {
      this.setState({ loginModalCallback: this.handlePurchaseClick })

      return this.props.toggleLoginModal({ show: true })
    }

    if (!this.props.payment || !this.props.payment.card || !this.props.payment.type) {
      return this.goToCheckout()
    }
  }

  handleBackButton = () => {
    this.props.goBack('/checkout')
  }

  handleAddCoupon = async (coupon) => {
    const response = await this.props.addCoupon(coupon)
    if (response.success) {
      this.handleCloseCouponModal()
    } else {
      this.setState({
        couponError: response.error,
      })
    }
  }

  handleRemoveCoupon = async () => {
    const response = await this.props.removeCoupon()

    if (!response.success) {
      this.setState({
        couponError: response.error
      })

      return
    }    

    this.handleCloseCouponModal()
  }

  onDismissCouponError = () => {
    this.setState({
      couponError: null,
    })
  }

  getCartProducts = () => {
    const products = this.props.items.map((item) => {
      const addons = []
      item.checkedAddons.forEach((addon) => {
        addon.items.forEach((addonItem) => {
          addons.push({
            id: addonItem.id,
            quantity: addonItem.quantity,
          })
        })
      })
      return {
        id: item.product.id,
        addons,
        quantity: item.quantity,
        observation: item.observation,
      }
    })
    return products
  }

  handleOpenAddressModal = () => {
    this.props.toggleAddressModal({ show: true, state: this.props.deliveryModality === 1 && this.props.deliveryTable ? 4 : 0 })
  }

  handleOpenCVVModal = () => {
    this.props.toggleCVVModal({ show: true, state: 1, callback: this.handleMakePayment })
  }

  handleMakePayment = async () => {
    const products = []

    this.props.items.forEach(function (item) {
      products.push({
        product: item.product,
        quantity: item.quantity,
        total: item.total
      })
    });

    const dataGTM = {
      'event': `FINALIZAR COMPRA - ${this.props.storeName}`,
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': 3, 'option': 'Abertura Carrinho' },
          'products': products.map(function (item) {
            return {
              name: item.product.name,
              id: item.product.id,
              price: item.price,
              brand: null,
              categoria: null,
              variant: null,
              quantity: item.quantity
            }
          })
        }
      }
    }

    this.addEventGTM(dataGTM)

    const paymentObject = {
      cardId: this.props.payment.card && this.props.payment.card.id,
      paymentType: this.props.payment.type,
      useCpf: this.props.useCpf,
      cvv: this.props.cvv,
      products: this.getCartProducts(),
      deliveryAddressId:
        this.props.deliveryAddress && this.props.deliveryAddress.id,
      deliveryModality: this.props.deliveryModality,
      deliveryPrice: this.props.deliveryPrice,
      brandId: this.props.payment.card && this.props.payment.card.brandId,
      cashChange: this.props.cashChange,
      couponId: this.props.coupon && this.props.coupon.id,
      isOfflinePayment: this.props.payment.isOffline,
      storeId: this.props.storeId,
      table: this.props.deliveryTable
    }

    const result = await this.props.placeOrder(paymentObject)

    this.props.saveCVV('')
    
    if (result.success) {
      this.props.handleGTM({
        'event': `COMPRA APROVADA - ${ this.props.storeName }`,
        'ecommerce': {
          'purchase': {
            'actionField': {
              'id': result.orderId,
              'affiliation': 'Pagamento Online',
              'revenue': this.props.cartTotal,
              'tax': 0,
              'shipping': this.props.deliveryPrice,
              'coupon': ''
            },
            'products': products.map(function (item) {
              return {
                name: item.product && item.product.name,
                id: item.product && item.product.id,
                price: item.product && item.product.price,
                brand: null,
                categoria: item.product && item.product.categoryName,
                variant: null,
                quantity: item.quantity
              }
            })
          }
        }
      })
      this.props.navigateTo('/finalizar-compra')

      return
    }

    this.props.showNotification({
      type: 'error',
      text: result.error
    })

    return
  }

  isCartComplete = () => {
    if (this.props.payment.card && this.props.deliveryModality) {
      return true
    }

    return false
  }

  getCheckoutText = () => {
    if (this.props.isCheckout) {
      if (this.isCartComplete()) return 'FINALIZAR COMPRA'
      else return 'ESCOLHER PAGAMENTO'
    } else {
      if (this.props.deliveryAddress && this.props.deliveryPrice !== null) {
        return 'COMPRAR'
      } else if (
        this.props.deliveryModality === 2 &&
        this.props.pickupPrice !== null
      ) {
        return 'COMPRAR'
      }
      return 'ESCOLHER FORMA DE ENTREGA'
    }
  }

  calculateCartDiscount = () => {
    const { payment, coupon, total } = this.props
    const card = payment.card
    if (coupon && coupon.value) {
      if (coupon.type === COUPON_TYPES.ABSOLUTE) {
        return coupon.value
      } else {
        return (coupon.value * total) / 100
      }
    } else {
      if (card && card.discount) {
        const discountAmount = (card.discountPercentage * total) / 100
        return Math.max(card.discountMaxValue, discountAmount)
      }
    }
    return 0
  }

  isMinimumValueReached = (selectedModality, total) => {
    const checkoutText = this.getCheckoutText();
    const isCheckoutText = checkoutText === 'COMPRAR' || checkoutText === 'FINALIZAR COMPRA';
    return isCheckoutText && selectedModality && selectedModality.minimumValue && 
      (total < selectedModality.minimumValue)
  }
;

  render() {
    const {
      items,
      total,
      coupon,
      modal,
      fixedPosition,
      fixedHeight,
      onClose,
      isCheckout,
      addCouponLoading,
      isLoggedIn,
      deliveryModality,
      deliveryAddress,
      name,
      storeLogo,
      modalities
    } = this.props

    const showEdit = (this.props.match.url === '/checkout' ? false : true)
    const selectedModality = modalities.find(modality => modality.id === deliveryModality);

    return (
      <>
        <Blocker />
        <CouponModal
          loading={addCouponLoading}
          isOpen={this.state.couponModalOpen}
          onClose={this.handleCloseCouponModal}
          coupon={coupon ? coupon.code : null}
          handleAddCoupon={this.handleAddCoupon}
          handleRemoveCoupon={this.handleRemoveCoupon}
          error={this.state.couponError}
          onDismissCouponError={this.onDismissCouponError}
        />
        <OutterBox
          fixedPosition={fixedPosition}
          fixedHeight={fixedHeight}
          isCheckout={isCheckout}
          cartMinimumValuePresent={this.isMinimumValueReached(selectedModality, total)}
          ref={this.cartBoxRef}
        >
          <CartHeader>
            {modal && (
              <CloseButtonWrapper>
                <button onClick={onClose}>
                  <Icon name='close' stroke='mediumGrey' fill='mediumGrey' />
                </button>
              </CloseButtonWrapper>
            )}
            <CartStoreName logo={storeLogo} name={name} />
            <Divider />
          </CartHeader>
          <CartContent>
            <CartDeliveryAddressCard
              address={this.props.deliveryAddress}
              table={this.props.deliveryTable}
              modality={this.props.deliveryModality}
              handleOpenAddressModal={this.handleOpenAddressModal}
              showEdit={showEdit}
            />
            <Divider />
            {items && items.length && this.isMinimumValueReached(selectedModality, total) ? 
              (
                <>
                  <CartMinimumValue 
                    minimumValue={selectedModality.minimumValue} 
                    deliveryModality={deliveryModality}
                  />
                  <Divider />
                </>
              ) : null
            }    
            <CartItemsList items={items} />
            {total > 0 && (
              <>
                {isLoggedIn && (
                  <>
                    <Divider />
                    <CartCouponCell
                      coupon={coupon ? coupon.code : null}
                      onClick={this.handleOpenCouponModal}
                    />
                  </>
                )}
                <Divider />
                <CartTotals
                  isCheckout={isCheckout}
                  subtotal={total}
                  discount={this.calculateCartDiscount()}
                  deliveryPrice={this.props.deliveryPrice}
                  deliveryModality={this.props.deliveryModality}
                  pickupPrice={this.props.pickupPrice}
                  deliveryAddress={this.props.deliveryAddress}
                />
              </>
            )}
            {isCheckout && this.props.useCpf && (
              <>
                <Divider />
                <CartCpfCell cpf={formatCpf(this.props.cpf)} />
              </>
            )}
            {isCheckout && this.props.payment.type && (
              <>
                <Divider />
                <CartPaymentMethod
                  brandId={this.props.payment.card.brandId}
                  type={this.props.payment.type}
                  text={this.props.payment.card.data}
                  onEdit={this.handleEditPayment}
                />
              </>
            )}
          </CartContent>
          <CartPurchaseButton
            loading={this.props.placeOrderLoading}
            isCheckout={isCheckout}
            disabled={this.isMinimumValueReached(selectedModality, total)}
            onClick={() => {
              if (!isMobile && ((deliveryModality === 4 && deliveryAddress) || deliveryModality === 2)) {
                this.handlePurchaseClick()

          
                return
              }

              if (!!isMobile && ((deliveryModality === 4 && deliveryAddress) || deliveryModality === 2)) {
                this.handleMobilePurchaseClick()
          
                return
              }

              if (!isMobile && deliveryModality === 1) {
                this.handlePurchaseClick()

          
                return
              }

              if (!!isMobile && deliveryModality === 1) {
                this.handleMobilePurchaseClick()
          
                return
              }
          
              this.handleOpenAddressModal()
            }}
            text={this.getCheckoutText()}
          />
        </OutterBox>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    deliveryAddress: state.cart.delivery.address,
    deliveryTable: state.cart.delivery.table,
    deliveryModality: state.cart.delivery.modality,
    deliveryPrice: state.store.deliveryPrice,
    pickupPrice: state.store.pickupPrice,
    storeId: state.store.id,
    storeName: state.store && state.store.name,
    storeLogo: state.store && state.store.logo,
    total: state.cart.total,
    coupon: state.cart.coupon,
    addCouponLoading: state.requests.addCoupon.loading,
    useCpf: state.cart.useCpf,
    cpf: state.user && state.user.cpf,
    payment: state.cart.payment,
    cvv: state.requests.saveCVV.cvv,
    cashChange: state.cart.cashChange,
    placeOrderLoading: state.requests.placeOrder.loading,
    isLoggedIn: !!state.user,
    loginModalOpen: state.ui.modals.loginModal.show,
    cartTotal: state.cart.total,
    modalities: state.store && state.store.modalities ? state.store.modalities : []
  }
}

export default connect(mapStateToProps, {
  navigateTo,
  addCoupon,
  removeCoupon,
  toggleAddressModal,
  toggleCVVModal,
  saveCVV,
  placeOrder,
  showNotification,
  toggleLoginModal,
  handleGTM
})(withRouter(CartBox))

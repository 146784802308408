import React from 'react'
import styled from 'styled-components'
import { connect } from "react-redux"
import Skeleton from 'react-loading-skeleton'

import { Button } from '../common/'
import { numberToCurrency } from '../../utils'
import MenuProductImage from './MenuProductImage'
import { ProductDetailModal } from '../product'
import { addCartItem } from '../../redux/actions/cart'
import {
    toggleAddressModal,
    toggleLoginModal
} from '../../redux/actions/ui'
import textLimiter from '../../utils/textLimiter'
import { Desktop, Mobile } from '../common/ResponsiveComponent'
import { device } from '../../constants/responsive'
import { MODAL_STATES as ADDRESS_MODAL_STATES } from '../address/AddressModal'
import { handleGTM } from '../../redux/actions/gtm'

import { CARDAPIO_CONSULTA } from "../../config"

const CardWrapper = styled.div`
    display: flex;
    background-color: white;
    height: 100px;
    cursor: pointer;

    @media ${device.desktop} {
        height: 140px;
    }
`

const ImageWrapper = styled.div`
    display: flex;
    padding-right: 18px;
    align-items: center;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ProductName = styled.span`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.06em;
    color: ${props => props.theme.colors.textBold};

    @media ${device.desktop} {
        font-size: 18px;
        margin-top: 9px;
        margin-bottom: 5px;
    }
`

const ProductDescription = styled.span`
    font-size: 11px;
    letter-spacing: -0.01em;
    flex-grow: 1;
    font-weight: 300;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.textPrimary};

    @media ${device.desktop} {
        font-size: 14px;
    }
`

const PriceAndButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const PriceWrapper = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${props => props.theme.colors.textBold};
    text-align: left;

    @media ${device.desktop} {
        font-size: 18px;
    }
`

const ButtonWrapper = styled.div`
    text-align: right;
`


class MenuProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.isActive,
            addressModalCallback: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isModalOpen !== this.state.isModalOpen ||
            (
                nextState.addressModalCallback !== this.state.addressModalCallback &&
                nextProps.addressModalOpen !== this.props.addressModalOpen
            ) ||
            nextProps.deliveryModality !== this.props.deliveryModality ||
            nextProps.hasProductInCart !== this.props.hasProductInCart ||
            nextProps.product !== this.props.product
        ) {
                return true;
            }
        return false;
    }

    componentDidUpdate(prevProps) {
        // Address modal closed?
        if (!this.props.addressModalOpen && prevProps.addressModalOpen) {
            // Has completed address?
            if (this.state.addressModalCallback && this.props.deliveryModality) {
                this.state.addressModalCallback();
                this.setState({addressModalCallback: null});
            }
        }
    }

    addGTMEvent = (args) => {
        /* const productName = args && args.

        const dataGTM = {
            'event': `PRODUTO - ${args.name}`,
            'ecommerce': {
                'click': {
                    'product': {
                        'name': args.name,
                        'id': args.id,
                        'price': args.price,
                        'brand': null,
                        'variant': null,
                        'list': `Cardapio - ${this.props.storeName}`,
                        'position': null
                    }
                }
            }
        } */
        this.props.handleGTM(args)
    }

    handleOpenModal = () => {
        if (!this.props.deliveryModality) {
            this.props.toggleAddressModal({show: true, state: ADDRESS_MODAL_STATES.CEP_SEARCH});
            this.setState({addressModalCallback: this.handleOpenModal})

            return
        }
        
        if (this.props.product) {
            this.setState({isModalOpen: true});
        }
    }

    hasMandatoryAddon = () => {
        let hasMandatory = false;
        this.props.product && this.props.product.addons.forEach(addon => {
            if (addon.min > 0) hasMandatory = true;
        })
        return hasMandatory;
    }

    handleAddToCart = (e) => {
        if (e) {
            e.stopPropagation()
        }

        if (this.hasMandatoryAddon()) {
            this.handleOpenModal()

            return
        }
        
        if (!this.props.deliveryModality) {
            this.props.toggleAddressModal({show: true, state: ADDRESS_MODAL_STATES.CEP_SEARCH})
            this.setState({addressModalCallback: this.handleAddToCart})

            return
        }
        
        this.handleAddCartItem({quantity: 1, checkedAddons: []});
    }

    handleCloseModal = () => {
        this.setState({isModalOpen: false});
    }

    handleAddCartItem = ({quantity, checkedAddons, observation}) => {
        if (!this.props.user) {
            this.props.toggleLoginModal({show: true, state: 0})
            return
        }

        if (this.props.user.signUpStatus === 2) {
            this.props.toggleLoginModal({show: true, state: 1})
            return
        }


        if ((!this.props.data.step || this.props.data.step === 1) && this.props.user.signUpStatus !== 1) {
            this.props.toggleLoginModal({show: true, state: 0})
            return
        }
        
        this.props.addCartItem({
            product: this.props.product, 
            quantity, 
            checkedAddons,
            observation
        })

        this.addGTMEvent({
            'event': `AddToCart`,
            'ecommerce': {
                'currencyCode': 'BRL',
                'add': {
                    'products': {
                        'name': this.props.product.name,
                        'id': this.props.product.id,
                        'price': this.props.product.price,
                        'brand': null,
                        'categoria': this.props.product.categoryName,
                        'variant': null,
                        'quantity': 1
                    }
                }
            }
        })
    }

    render() {
        const {
            product,
            hasProductInCart,
            storeName
        } = this.props;

        return (
            <>
                <CardWrapper onClick={() => {
                    this.handleOpenModal()

                    const gtmEventData = {
                        'event': `PRODUTO - ${product.name}`,
                        'ecommerce': {
                            'click': {
                                'product': {
                                    'name': product.name,
                                    'id': product.id,
                                    'price': product.price,
                                    'brand': null,
                                    'variant': null,
                                    'list': `Cardapio - ${ storeName}`,
                                    'position': null
                                }
                            }
                        }
                    }

                    this.addGTMEvent(gtmEventData)
                }}>
                    { product ? product.image && (
                        <ImageWrapper>
                            <MenuProductImage image={product.image} />
                        </ImageWrapper>
                    ) : (
                        <ImageWrapper>
                            <Skeleton width={100} height={100} />
                        </ImageWrapper>
                    )}
                    <ContentWrapper>
                        <ProductName>
                            {product && product.name ? product.name : <Skeleton />}
                        </ProductName>
                        <ProductDescription>
                            <Desktop>
                                {product && product.description
                                    ? textLimiter(product.description, 200, true)
                                    : <Skeleton count={3} />
                                }
                            </Desktop>
                            <Mobile>
                                {product && product.description
                                    ? textLimiter(product.description, 60, true)
                                    : <Skeleton count={3} />
                                }
                            </Mobile>
                        </ProductDescription>
                        <PriceAndButtonWrapper>
                            <PriceWrapper>
                                {product && product.price
                                    ? product.price !== 0 && numberToCurrency(product.price)
                                    : <Skeleton />
                                }
                            </PriceWrapper>
                            { CARDAPIO_CONSULTA === "false" && <ButtonWrapper>
                                {product
                                    ? (
                                        <Button big className={hasProductInCart ? "primary animated" : "default animated"} onClick={this.handleAddToCart}>
                                            {hasProductInCart ? `ADICIONAR + 1` : `ADICIONAR`}
                                        </Button>
                                    )
                                    : <Skeleton />}
                            </ButtonWrapper> }
                        </PriceAndButtonWrapper>
                    </ContentWrapper>
                </CardWrapper>
                <ProductDetailModal 
                    isOpen={this.state.isModalOpen} 
                    onClose={this.handleCloseModal} 
                    onUpdateCartItem={this.handleAddCartItem}
                    product={product}
                    addGTMEvent={this.addGTMEvent}
                />
            </>
        )
    }
}

MenuProductCard.defaultProps = {
    isActive: false
}

const mapStateToProps = (state, ownProps) => {
    return {
        deliveryModality: state.cart.delivery && state.cart.delivery.modality,
        addressModalOpen: state.ui.modals.addressModal.show,
        hasProductInCart: ownProps.product && !!state.cart.items.find(item => item.product.id === ownProps.product.id),
        data: state.requests.save,
        user: state.user,
        storeName: state.store.name,
    }
}

export default connect(
    mapStateToProps, {
        addCartItem,
        toggleAddressModal,
        toggleLoginModal,
        handleGTM
    }
)(MenuProductCard)

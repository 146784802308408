import {
    mapUpdatePhoneFormToAPI,
    mapPhoneFormToAPI,
    mapCodeFormToAPI,
    mapSignUpFormToAPI,
    mapLoginFormToAPI,
    mapAuthAPIResponse,
    mapFbLoginFormToAPI,
    mapGoogleLoginFormToAPI,
    mapForgotPasswordFormToAPI,
    mapSaveForm
} from '../../dto/auth'
import handleRequestError from '../../utils/handleRequestError'
import { actionTypes as requestsActionTypes } from './requests'
import {
    resetCart,
    setDeliveryAddress
} from './cart'
import { saveAddress } from './address'
import { getStoreInfoAndMenu } from './store'

export const actionTypes = {
    SET_USER: 'SET_USER'
}

const setUser = (user) => async (dispatch, getState, api) => {
    await dispatch({
        type: actionTypes.SET_USER,
        payload: user
    })

    // Save previous address
    const cartAddress = (JSON.parse(localStorage.getItem('cart'))).delivery.address

    if (user && cartAddress) {
        const response = await dispatch(saveAddress(cartAddress))
        if (response.success) {
            //fetch menu again
            dispatch(getStoreInfoAndMenu(getState().store.id))
        } else {
            dispatch(setDeliveryAddress({
                address: null,
                modality: getState().cart.delivery.modality
            }))
        }
    }
}

export const save = (form) => async (dispatch, getState, api) => {
    let result = {}
    try {
        const dto = mapSaveForm(form)

        dispatch({
            type: requestsActionTypes.REQUEST_SAVE_SIGN_UP,
            payload: {
                ...dto,
                success: true
            }
        })
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)
        result = {
            error: error.message
        }
    }
    return result
}

export const sendCode = (form) => async (dispatch, getState, api) => {
    const result = {}

    try {
        dispatch({
            type: requestsActionTypes.REQUEST_SEND_CODE,
            payload: {
                loading: true
            }
        })

        const {
            resend,
            confirmationType,
            authToken
        } = form

        const path = {}

        if (!resend) {
            Object.assign(path, {
                url: `Usuarios/ConfirmarTelefone`
            })
        }

        if (!!resend) {
            Object.assign(path, {
                url: `Usuarios/ReenviarCodigoVerificacao`
            })
        }

        if (confirmationType === 'phone') {
            Object.assign(path, {
                url: `Usuarios/ConfirmarTelefonePorVoz`
            })
        }

        const {
            url
        } = path
        const dto = mapPhoneFormToAPI(form)
        const apiResponse = await api.post(url, dto, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        const response = mapAuthAPIResponse(apiResponse.data)

        dispatch({
            type: requestsActionTypes.REQUEST_SEND_CODE,
            payload: {
                loading: false
            }
        })

        if (response.success) {
            dispatch({
                type: requestsActionTypes.REQUEST_SEND_CODE,
                payload: {
                    success: true
                }
            })
        } else {
            throw(new Error(response.message))
        }
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)
        
        Object.assign(result, {
            error: error.message
        })
    } finally {
        return result
    }
}

export const sendConfirmationCode = (form) => async (dispatch, getState, api) => {
    const result = {}

    try {
        dispatch({
            type: requestsActionTypes.REQUEST_SEND_CONFIRMATION_CODE,
            payload: {
                loading: true
            }
        })

        const {
            authToken
        } = form

        const url = `Usuarios/ConfirmarCodigoVerificacao`
        const dto = mapCodeFormToAPI(form)
        const apiResponse = await api.post(url, dto, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        const response = mapAuthAPIResponse(apiResponse.data)

        dispatch({
            type: requestsActionTypes.REQUEST_SEND_CONFIRMATION_CODE,
            payload: {
                loading: false
            }
        })

        if (response.success) {
            Object.assign(result, {
                success: true
            })

            dispatch({
                type: requestsActionTypes.REQUEST_SEND_CONFIRMATION_CODE,
                payload: {
                    success: true
                }
            })

            dispatch({
                type: requestsActionTypes.REQUEST_SIGN_UP,
                payload: {
                    success: true
                }
            })

            const user = getState().user;
            if (user && user.signUpStatus === 2) {
                await dispatch({
                    type: actionTypes.SET_USER,
                    payload: {
                        ...user,
                        signUpStatus: 1
                    }
                }) 
            }

        } else {
            throw(new Error(response.message))
        }
    } catch (e) {
        const error = handleRequestError(e)

        Object.assign(result, {
            error: error.message
        })
    } finally {
        return result
    }
}

export const updatePhone = (form) => async (dispatch, getState, api) => {
    const result = {}

    try {
        dispatch({
            type: requestsActionTypes.REQUEST_UPDATE_PHONE,
            payload: {
                loading: true,
            }
        })

        const {
            authToken
        } = form

        const url = `Usuarios/ConfirmarTelefone`
        const dto = mapUpdatePhoneFormToAPI(form)
        const apiResponse = await api.post(url, dto, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        const response = mapAuthAPIResponse(apiResponse.data)

        dispatch({
            type: requestsActionTypes.REQUEST_UPDATE_PHONE,
            payload: {
                loading: false,
            }
        })

        if (response.success) {
            Object.assign(result, {
                success: true
            })
            
            dispatch({
                type: requestsActionTypes.REQUEST_UPDATE_PHONE,
                payload: {
                    success: true
                }
            })
        } else {
            throw(new Error(response.message))
        }
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)

        Object.assign(result, {
            error: error.message
        })
    } finally {
        return result
    }
}

export const signUp = (form) => async (dispatch, getState, api) => {
    const result = {}
    try {
        dispatch({
            type: requestsActionTypes.REQUEST_SIGN_UP,
            payload: {
                loading: true,
            }
        })
        const url = `Usuarios/Cadastrar`
        const dto = mapSignUpFormToAPI(form)
        const apiResponse = await api.post(url, dto)

        const response = mapAuthAPIResponse(apiResponse.data)
        
        if (response.success) {
            Object.assign(result, {
                success: true,
                user: response.user
            })

            dispatch(setUser(response.user))
        }
        
        if (!response.success) {
            throw(new Error(response.message))
        }
    } catch (e) {
        const error = handleRequestError(e)
        
        Object.assign(result, {
            error: error.message
        })
    } finally {
        dispatch({
            type: requestsActionTypes.REQUEST_SIGN_UP,
            payload: {
                success: true
            }
        })
    
        return result
    }
}

export const login = (form) => async (dispatch, getState, api) => {
    const result = {}

    try {
        await dispatch({
            type: requestsActionTypes.REQUEST_LOGIN,
            payload: {
                loading: true
            }
        })

        const url = `Usuarios/Logar`
        const dto = mapLoginFormToAPI(form)
        const apiResponse = await api.post(url, dto)
        const response = mapAuthAPIResponse(apiResponse.data)

        if (response.success && response.user) {
            Object.assign(result, {
                success: true,
                user: response.user
            })

            dispatch(setUser(response.user))
        }

        if (!response.success) {
            throw(new Error(response.message))
        }
    } catch (e) {
        console.log(e)

        const error = handleRequestError(e)

        Object.assign(result, {
            error: error.message
        })
    } finally { 
        dispatch({
            type: requestsActionTypes.REQUEST_LOGIN,
            payload: {
                success: true
            }
        })

        return result
    }
}

export const fbLogin = (form) => async (dispatch, getState, api) => {
    let result = {}
    try {
        await dispatch({
            type: requestsActionTypes.REQUEST_LOGIN,
            payload: {
                loading: true,
            }
        })
        const url = `Usuarios/LogarViaFacebook`
        const dto = mapFbLoginFormToAPI(form)
        const apiResponse = await api.post(url, dto)
        const response = mapAuthAPIResponse(apiResponse.data)
        if (response.success) {
            if (response.user.signUp) {
                const userNames = form.name.split(' ')
                result = {
                    signUp: true,
                    user: {
                        name: userNames[0],
                        lastName: userNames[userNames.length - 1],
                        email: form.email,
                        image: form.picture.data.url
                    }
                }
            } else {
                result = {
                    success: true,
                    user: response.user
                }
                dispatch(setUser(response.user))
            }
        } else {
            throw(new Error(response.message))
        }
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)
        result = {
            error: error.message
        }
    }

    dispatch({
        type: requestsActionTypes.REQUEST_LOGIN,
        payload: {
            success: true
        }
    })

    return result
}

export const googleLogin = (form) => async (dispatch, getState, api) => {
    let result = {}
    try {
        await dispatch({
            type: requestsActionTypes.REQUEST_LOGIN,
            payload: {
                loading: true,
            }
        })
        const url = `Usuarios/LogarViaGoogle`
        const dto = mapGoogleLoginFormToAPI(form)
        const apiResponse = await api.post(url, dto)
        const response = mapAuthAPIResponse(apiResponse.data)
        if (response.success) {
            if (response.user.signUp) {
                result = {
                    signUp: true,
                    user: {
                        name: form.profileObj.givenName,
                        lastName: form.profileObj.familyName,
                        email: form.profileObj.email,
                        image: form.profileObj.imageUrl
                    }
                }
            } else {
                result = {
                    success: true,
                    user: response.user
                }
                dispatch(setUser(response.user))
            }
        } else {
            result = {
                error: response.message
            }
        }
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)
        result = {
            error: error.message
        }
    }

    dispatch({
        type: requestsActionTypes.REQUEST_LOGIN,
        payload: {
            success: true
        }
    })

    return result
}

export const forgotPassword = (email) => async (dispatch, getState, api) => {
    let result = {}
    try {
        await dispatch({
            type: requestsActionTypes.REQUEST_FORGOT_PASSWORD,
            payload: {
                loading: true,
            }
        })
        const url = `Usuarios/RecuperarSenha`
        const dto = mapForgotPasswordFormToAPI(email)
        const apiResponse = await api.post(url, dto)
        const response = mapAuthAPIResponse(apiResponse.data)
        if (response.success) {
            result = {
                success: true,
            }
        } else {
            throw(new Error(response.message))
        }
    } catch (e) {
        console.log(e)
        const error = handleRequestError(e)
        result = {
            error: error.message
        }
    }

    dispatch({
        type: requestsActionTypes.REQUEST_FORGOT_PASSWORD,
        payload: {
            success: true
        }
    })

    return result
}

export const logout = (form) => async (dispatch, getState, api) => {
    dispatch(resetCart())
    dispatch(setUser(null))
}

import React, {
  useState
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import {
  fetchAvailableCardBrands,
  deleteCard,
  addCard,
  addTicketCard
} from '../../../redux/actions/payment'
import {
  setPaymentType,
  fetchPaymentMethods
} from '../../../redux/actions/fridge'
import {
  setCpfOnReceit,
  setPaymentCashChange
} from '../../../redux/actions/cart'

import { OnlinePaymentBlock } from '../../../components/payment'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 960px;
  margin-top: 0px;
  min-width: 320px;
  padding-top: 40px;
`;

const H2 = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
`

const ButtonContainer = styled.button`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #41BAC8;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  flex: none;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px 0px;
  width: 100%;
  max-width: 280px;
`

function Cards(props) {
  const {
    setContent
  } = props

  const [selectedCard, setSelectedCard] = useState({})
  // const [showDeleteCardModal, setShowDeleteCardModal] = useState(false)
  // const [deleteCard, setDeleteCard] = useState(null)

  const handleDeleteCard = (card) => {
    // setShowDeleteCardModal(true)
    // setDeleteCard(card)
  }

  const handleCardSelect = (card, check) => {
    if (check) {
      setSelectedCard(card)
      props.setPaymentType({type: card.paymentType, card})

      return
    }
    
    setSelectedCard(null)
  }

  const handleOnlineCardSelect = (card, check) => {
    handleCardSelect(card, check, false)
  }

  const handleShowAddNewCardModal = () => {
    setContent('new-card')
  }

  return <Wrapper>
    <H2>
      Cartões cadastrados
    </H2>

    <OnlinePaymentBlock 
      cards={props.onlineCards}
      onCheckCard={handleOnlineCardSelect}
      onDeleteCard={handleDeleteCard}
      onAddCard={handleShowAddNewCardModal}
      selected={selectedCard}
    />

    <ButtonContainer onClick={() => {
      setContent('with-card')
    }}>Voltar</ButtonContainer>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.store && state.store.name,
    userName: state.user && state.user.name,
    cardBrand: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.brandId,
    cardNumber: state.fridge && state.fridge.selectedCard && state.fridge.selectedCard.data,
    onlineCards: state.fridge && state.fridge.onlineCards
  }
}

export default connect(
  mapStateToProps, {
    fetchPaymentMethods,
    fetchAvailableCardBrands,
    setPaymentType,
    setCpfOnReceit,
    deleteCard,
    addCard,
    addTicketCard,
    setPaymentCashChange
  }
)(withRouter(Cards))

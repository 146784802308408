import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import {
  ESTABELECIMENTO_ID,
  MESA,
  MULTI_STORE
} from '../config'

import { device } from '../constants/responsive'

import {
  setSelectedStore,
  getStoreInfoAndMenu,
  setDeliveryCounter
} from '../redux/actions/store'
import { toggleAddressModal } from '../redux/actions/ui'

import { HeaderBox } from '../components/header'
import { CartBox } from '../components/cart'
import { PaymentSelectBox } from '../components/payment'
import { Desktop } from '../components/common/ResponsiveComponent'
import { AddressModal } from '../components/address'
import { CVVModal } from '../components/payment'
import PageNavigator from '../components/utilities/PageNavigator'

import { navigateTo } from '../redux/actions/pages'

const AppWrapper = styled.div`
  display : flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
`

const HeaderContainer = styled.div`
  width: 100%;
`

const MainContainer = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  width: 100%;

  @media ${device.desktop} {
    width: 80%;
    min-width: 80%;
    margin-bottom: 20px;
    margin-top: 0px;
  }
`

const CartBoxWrapper = styled.div`
  @media ${device.desktop} {
    height: 100%;
    margin-left: 15px;
    margin-top: 20px;
    width: 326px;
  }
`

class PaymentPage extends React.Component {

  redirectIfNecessary = () => {
    if (!this.props.isLoggedIn || this.props.totalItems <= 0) {
      this.props.navigateTo('/')
    }
  }

  async componentDidMount() {
    this.redirectIfNecessary();

    const {
      storeId,
      deliveryTable,
      deliveryModality,
      deliveryAddress
    } = this.props

    if (!storeId && MULTI_STORE === 'false') {
      await this.props.setSelectedStore(ESTABELECIMENTO_ID)
    }

    if (!deliveryModality) {
      this.props.toggleAddressModal({ show: true, state: 0 })
    }

    if (deliveryModality === 4 && !deliveryAddress) {
      this.props.toggleAddressModal({ show: true, state: 0 })
    }

    if (MESA === 'true' && deliveryModality === 1 && !deliveryTable) {
      await this.props.setDeliveryCounter()
      this.props.toggleAddressModal({ show: true, state: 4 })
    }

    if (storeId) {
      await this.props.getStoreInfoAndMenu(storeId)
    }
  }

  componentWillMount() {
    this.redirectIfNecessary();
  }

  componentDidUpdate() {
    this.redirectIfNecessary();
  }

  render() {
    const {
      storeId
    } = this.props
    
    return (
      storeId && <AppWrapper>
        <CVVModal />
        <AddressModal />
        <PageNavigator />
        <HeaderContainer>
          <Desktop>
            <HeaderBox stretch={true} />
          </Desktop>
        </HeaderContainer>
        <MainContainer>
          <PaymentSelectBox />
          <Desktop>
            <CartBoxWrapper>
              <CartBox isCheckout={true}  />
            </CartBoxWrapper>
          </Desktop>
        </MainContainer>
      </AppWrapper>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: !!state.user,
        totalItems: state.cart.items.length,
        storeId: state.store && state.store.id,
        deliveryAddress: state.cart && state.cart.delivery && state.cart.delivery.address,
        deliveryTable: state.cart && state.cart.delivery && state.cart.delivery.table,
        deliveryModality: state.cart && state.cart.delivery && state.cart.delivery.modality
    }
}

export default connect(
  mapStateToProps, {
    setSelectedStore,
    getStoreInfoAndMenu,
    setDeliveryCounter,
    toggleAddressModal,
    navigateTo
  }
)(PaymentPage)

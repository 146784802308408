import { actionTypes } from "../actions/cart";
import { DELIVERY_MODALITIES } from "../../constants/delivery";
import { makeCartItemHash } from "../../utils";

const sessionCart = JSON.parse(localStorage.getItem("cart"))

const initialState = {
  delivery: {
    modality: null,
    address: null,
  },
  payment: {
    type: null,
    card: null,
  },
  cashChange: null,
  coupon: {
    id: null,
    code: null,
    value: null,
    type: null,
  },
  useCpf: false,
  items: [],
  total: 0,
  order: {},
};

const loadedState = {
  ...initialState,
  ...sessionCart,
};

const calculateCartTotal = (items) => {
  const total =
    items && items.reduce((acc, item) => acc + calculateItemTotal(item), 0);
  return total;
};

const calculateItemTotal = (item) => {
  let total = item.product.price;
  item.checkedAddons.forEach((addon) => {
    addon.items.forEach((addonItem) => {
      total += addonItem.price * addonItem.quantity;
    });
  });
  total *= item.quantity;
  return total;
};

const cartReducer = (state = loadedState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionTypes.SET_NO_DELIVERY: {
      newState = {
        ...state,
        delivery: {
          modality: null
        }
      }
      break
    }
    case actionTypes.SET_DELIVERY_PICKUP: {
      newState = {
        ...state,
        delivery: {
          modality: DELIVERY_MODALITIES.PICKUP
        }
      }
      break
    }
    case actionTypes.SET_DELIVERY_COUNTER: {
      newState = {
        ...state,
        delivery: {
          modality: DELIVERY_MODALITIES.COUNTER,
        },
      };
      break;
    }
    case actionTypes.SET_DELIVERY_ADDRESS: {
      const {
        modality,
        address
      } = action.payload

      newState = {
        ...state,
        delivery: {
          modality,
          address,
        },
      };
      break;
    }
    case actionTypes.SET_DELIVERY_TABLE: {
      newState = {
        ...state,
        delivery: {
          modality: DELIVERY_MODALITIES.COUNTER,
          table: action.payload
        }
      }

      break
    }
    case actionTypes.SET_PAYMENT_TYPE: {
      newState = {
        ...state,
        payment: {
          type: action.payload.type,
          card: action.payload.card,
          isOffline: action.payload.isOffline,
        },
      };
      break;
    }
    case actionTypes.SET_CASH_CHANGE: {
      newState = {
        ...state,
        cashChange: action.payload,
      };
      break;
    }
    case actionTypes.REMOVE_ITEM: {
      const product = action.payload.product;
      const newItems = [...state.items];
      const foundIndex = state.items.findIndex(
        (el) => el.product.id === product.id
      );
      if (foundIndex > -1) {
        newItems.splice(foundIndex, 1);
      }
      newState = {
        ...state,
        items: newItems,
        total: calculateCartTotal(newItems),
      };
      break;
    }
    case actionTypes.ADD_ITEM: {
      const { product, quantity, checkedAddons, observation } = action.payload;
      const localCart = JSON.parse(localStorage.getItem("cart"));
      const newItems = (localCart && localCart.items) || [];
      const newHash = makeCartItemHash(product, checkedAddons, observation);
      const foundIndex = state.items.findIndex((el) => el.hash === newHash);
      const total = {
        value: calculateItemTotal({ product, quantity, checkedAddons })
      }
      if (foundIndex > -1) {
        Object.assign(total, {
          value: total.value = calculateItemTotal({ product, quantity: newItems[foundIndex].quantity + quantity, checkedAddons })
        })

        newItems[foundIndex].quantity = newItems[foundIndex].quantity + quantity
        newItems[foundIndex].total = total.value
      }
      
      if (quantity > 0 && foundIndex <= -1) {
        newItems.push({
          product: product,
          checkedAddons: checkedAddons,
          quantity: quantity,
          total: total.value,
          observation,
          hash: newHash,
        });
      }

      newState = {
        ...state,
        items: newItems,
        total: calculateCartTotal(newItems),
      };
      break;
    }
    case actionTypes.UPDATE_ITEM: {
      const {
        product,
        quantity,
        checkedAddons,
        observation,
        hash,
      } = action.payload;
      const newItems = [...state.items];
      const newHash = makeCartItemHash(product, checkedAddons, observation);
      const foundIndex = state.items.findIndex((el) => el.hash === hash);
      const total = {
        value: calculateItemTotal({ product, quantity, checkedAddons })
      }
      if (foundIndex > -1) {
        Object.assign(total, {
          value: total.value = calculateItemTotal({ product, quantity: quantity, checkedAddons })
        })

        newItems[foundIndex].quantity = quantity
        newItems[foundIndex].total = total.value

        if (quantity <= 0) newItems.splice(foundIndex, 1);
        else {
          newItems[foundIndex] = {
            product: product,
            checkedAddons: checkedAddons,
            quantity: newItems[foundIndex].quantity,
            total: total.value,
            observation,
            hash: newHash,
          };
        }
      }
      newState = {
        ...state,
        items: newItems,
        total: calculateCartTotal(newItems),
      };
      break;
    }
    case actionTypes.SET_CPF_ON_RECEIPT: {
      newState = {
        ...state,
        useCpf: action.payload,
      };
      break;
    }
    case actionTypes.SET_CART_COUPON: {
      newState = {
        ...state,
        coupon: action.payload,
      };
      break;
    }
    case actionTypes.DELETE_CART_COUPON: {
      newState = {
        ...state,
        coupon: null,
      }
      break
    }
    case actionTypes.SET_ORDER_COMPLETED: {
      newState = {
        ...state,
        order: {
          completed: true,
          id: action.payload.orderId,
          deliveryEstimate: action.payload.deliveryEstimate,
          deliveryStatus: action.payload.deliveryStatus,
        },
      };
      break;
    }
    case actionTypes.CLEAR_CART_STORAGE: {
      localStorage.removeItem("cart")
      return newState;
    }
    case actionTypes.CLEAR_CART_MODALITY: {
      newState = {
        ...state,
        items: [],
        total: 0,
      }
      break
    }
    case actionTypes.RESET_CART: {
      newState = initialState;
      break;
    }
    default: {
      newState = state;
      break;
    }
  }

  localStorage.setItem("cart", JSON.stringify(newState));
  return newState;
};

export default cartReducer;
